<template>
  <div id="app">
    <div class="wrap">
      <div class="fleft">
        <div v-if="isMobile()" class="main-title-mobile">Liontaria</div>
        <div v-else class="main-title">Liontaria</div>
      </div>
        <div class="fright">
          <v-img class="logo"
          :src= "require('@/assets/liontaria_cat_face.png')"
          :height="200"
          :width="200"
          aspect-ratio="16/9">
          </v-img>
        </div>
    </div>
  
    <NavBar></NavBar>
    <a href="https://www.instagram.com/liontaria_official/">
      <v-img class="floating-tab"
        :src= "require('@/assets/instagram.png')"
        :height="30"
        :width="30"
        aspect-ratio="16/9">
      </v-img>
    </a>
    
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,

  },
  data() {
    isMobile=false;
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log('mobile');
        return true;
      } else {
        console.log('desktop');
        return false;
      }
    },
  },
};

</script>

<style>
#app {
  font-family: Garamond, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.wrap {
width: 100%;
overflow:auto;
}

.fleft {
    float:left;
    width: 75%;
    padding-top: 80px;
    background:white;
    height: 200px;

}

.fright {
    float: right;
    background:white;
    height: 200px;
    width: 25%;
}
.h2{
  font-size: 36px;
}

.li {
  border-right: 1px solid #bbb;
}

h2 {
  text-align: right;
  padding-right: 25%;
  padding-bottom: 2%;
}

.main-title{
  font-size: 4vw;
  text-align: right;
  padding-right: 23%;
}
.main-title-mobile{
  font-size: xx-large;
}
.floating-tab{
  float:right;
  z-index: 1;
  position: fixed; /* Set the navbar to fixed position */
  top:40%; /* Position the navbar at the top of the page */
  right: 10px;
  width: 100px; /* Full width */
}
:root {
  color-scheme: only light;
}

</style>
