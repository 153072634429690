<template>
 <v-card class="card" @pointerenter="warnDisabled" :class="{ rotate: disabled }" style="background-color: rgb(255, 248, 241); color: black; ">
  <v-card-item>
    <v-card-title class="custom-text">
      <v-spacer />
      <div class="custom-title">
        {{ title }}
      </div>
      <v-spacer />
    </v-card-title>
  </v-card-item>

  <v-card-text class="custom-text">
    {{subtitle}}
  </v-card-text>
    <v-card-actions>

    </v-card-actions>
</v-card>


</template>

<script>

export default {
  name: 'NormalCard',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    
  
  },
  data() {
    return {
      disabled: false,
    };
  },

  methods: {
    warnDisabled() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 1500);
    },
  },
  components: {

  },
};
</script>

<style scoped>
.bg {
  background-size: cover;
  background-position: center;
  text-align: center;

  opacity: 50%;
  height: 100%;
  background-color: white;
}
.card {
  width: 400px;
  word-break: break-all;

}

.custom-title {
  font-size: 18px;
  
  color: rgb(84, 35, 35); /* Adjust the font size as needed */
  /* Add any other title styles you need */

}
.custom-title{
  font-size: 15px;
  word-break: break-all;
}
.custom-text {
  font-size: 12px; 
  /* Add any other text styles you need */
  word-break: break-all;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.rotate {
  animation: rotate 0.5s ease both;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-5deg); /* Adjust the rotation angle as per your preference */
  }
}

</style>
