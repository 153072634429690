<template>
    <div>
        <FullHeightBanner title="Willkommen bei Liontaria" msg= 'rolling_stuhl.jpg' side="center"/>
        <router-link to="/">
          <FullScreenBanner v-if="isMobile()" title="Senioren WG" subtitle="Alles über unser aktuelles Projekt" msg= 'grandma_washing.jpg' side="right"/>
          <FullScreenBanner v-else title="Senioren WG" subtitle="Alles über unser aktuelles Projekt" msg= 'grandma_washing_long.jpg' side="right"/>
        </router-link>
        <router-link to="/about">
          <FullScreenBanner title="Über uns" subtitle="Lesen Sie hier mehr über Kopf und Herz hinter Liontaria" side="left" msg= 'blumen.jpg'/>
        </router-link>
       
        <router-link to="/project">
          <FullScreenBanner title="Umsetzung" subtitle="Hier finden Sie alles zum aktuellen Stand und unsere allgemeine Umsetzung" msg= 'plan.jpg' side="right"/>
        </router-link>
        
        <FooterComponent></FooterComponent>
    </div>
    </template>
<script>

import FullScreenBanner from './FullScreenBanner.vue';
import FooterComponent from './Footer.vue';
import FullHeightBanner from './FullHeightBanner.vue';

export default {
  name: 'HomePage',
  components: {
    FullScreenBanner,
    FooterComponent,
    FullHeightBanner,

  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
};
</script>

<style scoped>


</style>
