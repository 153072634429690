import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import AboutUs from './components/AboutUs.vue';
import Home from './components/Home.vue';
import ProjectWork from './components/ProjectWork.vue';
import i18n from '@/plugins/i18n';
import OurProjects from './components/OurProjects.vue';

Vue.use(VueRouter);

Vue.config.productionTip = false;


const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/ourProjects',
      component: OurProjects,
    },
    {
      path: '/about',
      component: AboutUs,
    },
    {
      path: '/project',
      component: ProjectWork,
    },
  ],
});
new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

