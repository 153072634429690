<template>
  <div>
    <div class="small-photo">
            <v-img 
            :src="img"
            rounded
            class="rounded-image">
            </v-img> 
        </div>
      <v-row class="description-text">
        <v-spacer v-if="!isMobile()"></v-spacer>
        <v-col class="individual-text-column" >{{date0}}</v-col>
        <v-col cols="8">{{subtitle0}}</v-col>
        <v-spacer v-if="!isMobile()"></v-spacer>
      </v-row>
      <v-row class="description-text">
        <v-spacer v-if="!isMobile()"></v-spacer>
        <v-col class="individual-text-column">{{date1}}</v-col>
        <v-col cols="8">{{subtitle1}}</v-col>
        <v-spacer v-if="!isMobile()"></v-spacer>
      </v-row>
  </div>
  
   
</template>
  
<script>
export default {
  name: 'PhotoWithText',
  props: {
    subtitle0: {
      type: String,
    },
    date0: {
      type: String,
    },
    subtitle1: {
      type: String,
    },
    date1: {
      type: String,
    },
    msg: String,
  
  },
  data: () => ({
    mainProps: {blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1'},
  }),
  computed: {
    img() {
      return require('@/assets/' + this.msg + '');
    },
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
};

</script>


<style scoped>
.small-photo {
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 260px;
    border-radius: 50%;
    
}

/* Place text in the middle of the image*/

.description-text {
    color: black;
    font-size: small;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 35px;


}
.individual-text-column{
    margin-right: 40px;
}
.rounded-image{
  border-radius: 45%; 
}

</style>
