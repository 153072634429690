<template>
  <div>
    <div class="small-photo">
            <v-img 
            :src="img"
            rounded
            class="rounded-image">
            </v-img> 
        </div>
        <div class="individual-text-column" cols="3">{{date0}}</div>
        <div class="description-text">{{subtitle0}}</div>
  </div>
  
   
</template>
  
<script>
export default {
  name: 'SmallPhotoWithText',
  props: {
    subtitle0: {
      type: String,
    },
    date0: {
      type: String,
    },
    subtitle1: {
      type: String,
    },
    date1: {
      type: String,
    },
    msg: String,
  
  },
  data: () => ({
    mainProps: {blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1'},
  }),
  computed: {
    img() {
      return require('@/assets/' + this.msg + '');
    },
  },
};

</script>


<style scoped>
.small-photo {
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    /* border-radius: 50%; */
    
}

/* Place text in the middle of the image*/

.description-text {
    color: black;
    font-size: small;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 20px;
    align-self: center;


}
.individual-text-column{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: larger;
}
.rounded-image{
  border-radius: 100%; 
}

</style>
