<template>
    <div>
        <!-- replace with margin/padding -->
        <v-container class="bg-surface-variant full-screen-banner">
            <vs-row>
            </vs-row>
        </v-container>
      <v-img
          :src="require('../assets/blumen_lighter.jpg')"
          :height="fit-content"
          aspect-ratio="16/9"
          >
          <div>
            <v-row>
            <v-responsive v-if="isMobile()" width="100%"></v-responsive>
                <v-col v-if="isMobile()" class="column">
                   <JustText class="text" text1="Zwei junge und motivierte
                       Leisnigerinnen möchten mit neuen Ansätzen,
                       dem Einbringen
                       sozialer Werte und dem Wunsch nach Veränderung
                       in der heutigen Welt, so viele
                       Menschen wie möglich begeistern
                       und vernetzen."
                       text2="wir sind Melli und Anna
                       - zwei Löwinnen aus Leidenschaft.
                       Seit einigen Jahren verfolgen
                       wir ein Ziel: wir möchten soziale Arbeit
                       wieder gerechter und just sozialer gestalten.
                       Bisher konnten wir einige Erfahrungen in
                       Pflegeeinrichtungen und -kassen sammeln,
                       welche den Grundstein unserer Arbeit
                       darstellen wird."
                       text3="Mit dem Errichten einer Senioren-WG,
                       möchten wir unseren ersten Beitrag
                       für die Gesellschaft leisten.
                       Wir freuen uns sehr auf diesen gemeinsamen
                       Weg und auf alles, was
                       noch  auf uns zukommen
                       wird."
                       highlighted="Hi">
                    </JustText>
                </v-col>
                <v-responsive v-if="isMobile()" width="100%"></v-responsive>
                <v-col class="column">
                    <PhotoWithText msg= 'partner_melli_cropped.jpg'
                    :subtitle0="$t('aboutUsText.personalTextMeli')"
                    :date0="$t('aboutUsText.dateMeli0')"
                    :subtitle1="$t('aboutUsText.projectRelationMeli')"
                    :date1="$t('aboutUsText.dateMeli1')"/>
                </v-col>

                <v-responsive  v-if="isMobile()" width="100%"></v-responsive>

                <v-col v-if="!isMobile()">
                   
                    <JustText class="text" text1="Zwei junge und motivierte
                        Leisnigerinnen möchten mit neuen Ansätzen,
                        dem Einbringen
                        sozialer Werte und dem Wunsch nach Veränderung
                        in der heutigen Welt, so viele
                        Menschen wie möglich begeistern
                        und vernetzen."
                        text2="wir sind Melli und Anna
                        - zwei Löwinnen aus Leidenschaft.
                        Seit einigen Jahren verfolgen
                        wir ein Ziel: wir möchten soziale Arbeit
                        wieder gerechter und just sozialer gestalten.
                        Bisher konnten wir einige Erfahrungen in
                        Pflegeeinrichtungen und -kassen sammeln,
                        welche den Grundstein unserer Arbeit
                        darstellen wird."
                        text3="Mit dem Errichten einer Senioren-WG,
                        möchten wir unseren ersten Beitrag
                        für die Gesellschaft leisten.
                        Wir freuen uns sehr auf diesen gemeinsamen
                        Weg und auf alles, was
                        noch  auf uns zukommen
                        wird."
                        highlighted="Hi">
                        </JustText>


                </v-col>
                <v-responsive  v-if="isMobile()" width="100%"></v-responsive>
                <v-col class="column">
                    <PhotoWithText msg= 'partner_anna_cropped.jpg'
                    :subtitle0="$t('aboutUsText.personalTextAnna')"
                    :date0="$t('aboutUsText.dateAnna0')"
                    :subtitle1="$t('aboutUsText.projectRelationAnna')"
                    :date1="$t('aboutUsText.dateAnna1')"/>
                </v-col>
            </v-row>
          </div>
        </v-img>


        <FooterComponent></FooterComponent>


    </div>
</template>
<script>

import PhotoWithText from './PhotoWithText.vue';
import JustText from './Text.vue';
import FooterComponent from './Footer.vue';
export default {
  name: 'AboutUsPage',
  

  components: {
    PhotoWithText,
    JustText,
    FooterComponent,
  },
  data: () => ({
    // mainProps: {
    //   melliText: this.$t('aboutUsText.personalTextMeli'),
    //   blankColor: '#777',
    //   width: 75,
    //   height: 75,
    //   class: 'm1'
    // },
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
};
</script>


<style-scoped>

.column{
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 0%;
    padding-right: 0%;

}
.text{
  opacity: 60%
}


</style>


