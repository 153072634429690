<template>
   <div>
    <div class="spacer"></div>
    <div class="image">
      <FullScreenCenterBanner
        class="what-now-container"
        :title="$t('projectWork.whatNowTitle')"
        :subtitle0="$t('projectWork.whatNowSubtitle')"/>

        <div class="divider"></div>
          <v-container class="center">
            <v-row
            align-content="center">   
            <v-spacer v-if="!isMobile()"></v-spacer>
              <v-col cols="7" lg="2" xs="3" class="center">
                <NormalCard 
                :title="$t('projectWork.card0.title')"
                :subtitle="$t('projectWork.card0.subtitle')">
                </NormalCard>
                <div class="spacer"></div>
                <NormalCard 
                :title="$t('projectWork.card1.title')"
                :subtitle="$t('projectWork.card1.subtitle')">
              </NormalCard>
              </v-col>
              <v-col cols="7" lg="2" xs="3" class="center">
                <NormalCard 
                :title="$t('projectWork.card2.title')"
                :subtitle="$t('projectWork.card2.subtitle')">
                </NormalCard>
                <div class="spacer"></div>
                <NormalCard 
                :title="$t('projectWork.card3.title')"
                :subtitle="$t('projectWork.card3.subtitle')">
                </NormalCard>
              </v-col>
              <v-col cols="7" lg="2" xs="3" class="center">
                <NormalCard 
                :title="$t('projectWork.card4.title')"
                :subtitle="$t('projectWork.card2.subtitle')">
                </NormalCard>
                <div class="spacer"></div>
                <NormalCard 
                :title="$t('projectWork.card5.title')"
                :subtitle="$t('projectWork.card3.subtitle')">
                </NormalCard>
              </v-col>
              <v-spacer v-if="!isMobile()"></v-spacer>
            </v-row>
            <div class="divider"></div>
          </v-container>
      
    </div>
      <FullScreenCenterBanner
        class="our-goals-container"
        :title="$t('projectWork.ourGoals.title')"
        :subtitle0="$t('projectWork.ourGoals.paragraph0')"
        :subtitle1="$t('projectWork.ourGoals.paragraph1')"
        :subtitle2="$t('projectWork.ourGoals.paragraph2')"/>
  
      <div class="our-goals-container">
        <v-container class="center ">
          <v-row>   
            <v-spacer v-if="!isMobile()"></v-spacer>
            <v-col cols="7" lg="2" xs="3" class="center">
              <p class="xx-spacer">{{ $t('projectWork.ourGoals.argument0') }}</p>
              <v-divider></v-divider>
 
              <p class="xx-spacer">{{ $t('projectWork.ourGoals.argument1') }}</p>
              <v-divider></v-divider>
              <p class="xx-spacer">{{ $t('projectWork.ourGoals.argument2') }}
              </p><v-divider></v-divider>
            </v-col>
            <div v-if="!isMobile()" class="horizontal-divider"></div>
            <v-col cols="7" lg="2" xs="3" class="center">
              
              <p class="xx-spacer">{{ $t('projectWork.ourGoals.argument3') }}</p>
              <v-divider></v-divider>
              <p class="xx-spacer">{{ $t('projectWork.ourGoals.argument4') }}</p>
              <v-divider></v-divider>
              
            </v-col>
            <v-spacer v-if="!isMobile()"></v-spacer>
          </v-row>
          <div class="divider"></div>
        </v-container>
        
      </div>
      <div class="relevant-links">
        
        <v-container class="center ">
          <div class="spacer"></div>
          <v-row>   
            <v-spacer v-if="!isMobile()"></v-spacer>
            <v-col cols="7" lg="3" xs="3" class="center">
              <p>{{ $t('projectWork.pieChart.title') }}</p>
              <v-img
                :width="400"
                :height="200"
                aspect-ratio="16/9"
                cover
                src="..//assets/Omi.png"
              ></v-img>
            </v-col>
            <div v-if="!isMobile()" class="horizontal-divider"></div>
            <v-col cols="7" lg="3" xs="3" class="center shake">
              <p>{{ $t('projectWork.supportUs.title') }}</p>
              <a href="https://www.betterplace.me/wg-mal-anders">
                <v-img
                  :width="400"
                  :height="200"
                  aspect-ratio="16/9"
                  cover
                  src="..//assets/beitragen.png"
                ></v-img>
              </a>
                
              
            </v-col>
            <v-spacer v-if="!isMobile()"></v-spacer>
          </v-row>
   
        </v-container>
        
      </div>
  <FooterComponent></FooterComponent>
  
  </div>

  
</template>

<script>
import FooterComponent from './Footer.vue';
import FullScreenCenterBanner from './FullScreenCenterBanner.vue';
import NormalCard from './ui/NormalCard.vue';

export default {
  name: 'ProjectWorkUs',


  data() {
    return {
      disabled: false,
    };
  },

  methods: {
    warnDisabled() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 1500);
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
  components: {
    FooterComponent,
    FullScreenCenterBanner,
    NormalCard,

  },
};

</script>

<style scoped>

.image{
  /* background-image: require('@/assets/' + blumen.jpg + ''); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("..//assets/macbook_pen.jpg");
  background-size: auto;
  background-size: 100% 100%;
  /* margin-right: auto;
  margin-left: auto; */
}


.custom-title {
  font-size: 18px;
  color: rgb(84, 35, 35); /* Adjust the font size as needed */
  /* Add any other title styles you need */
}

.custom-text {
  font-size: 8px; /* Adjust the font size as needed */
  /* Add any other text styles you need */
}

.custom-column{
  padding-left: 20px;
}
.custom-row{
margin: 1px;
}

.center{
  margin-right:auto;
  margin-left: auto;

}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.spacer{
  margin-bottom: 15px;
}
.x-spacer {
  padding-top: 50px;
}
.divider{
  margin-top: 10%;
}
.what-now-container{
  background-color: #5c4e44;
  color:white;
}
.our-goals-container{
  background-color: rgb(234, 228, 220);
  color:black;
}
.relevant-links{
  background-color:  #5c4e44;
  color:white;
}
.xx-spacer{
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: larger;
}
.xxx-spacer{
  padding-top: 100px;
  padding-bottom: 50px;
  font-size: larger;
}
.horizontal-divider{
  margin-right: 50px;
}
/* 
.piechart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: conic-gradient(
        rgb(250, 236, 238) 270deg,
        lightblue 0 0deg,
        rgb(170, 156, 130) 0
    );
} */
</style>
