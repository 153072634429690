<template>
    <div class="bg column">
        <div class="description-title" >{{text1}}</div>
        <p class="description-text"><span class="highlighted">{{ highlighted }}</span>{{text2}}</p>
        <p class="description-text">{{text3}}</p>
    </div>
</template>
  
<script>
export default {
  name: 'threeFold',
  props: {
    text1: String,
    text2: String,
    text3: String,
    highlighted: String,
  },
  data: () => ({


  }),
  computed: {
  },
};

</script>

<style scoped>

.description-text {
    color: black;
    font-size: big;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    text-align: center;
    line-height: 35px;
}
.description-title {
    color: black;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    text-align: center;
    line-height: 35px;
    font-size: 25px;
}

.bg {
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 10% 0vw;
    opacity: 100%;
    height: 100%;
    background-color: white;
}
.highlighted {
    font-size: 25px;
    font-weight: bold;
    padding-right: 7px;
}
.column{
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
</style>
