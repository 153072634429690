import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'de': {
    welcomeMessage: 'this is a test',
    aboutUsText: {
      dateMeli0: '2019 - heute',
      dateMeli1: 'Bezug zum Projekt',
      personalTextMeli: 'Nach dem Abitur begann Melissa ihre Ausbildung bei der Krankenkasse als Kauffrau im Gesundheitswesen (Schwerpunkt Vertrieb&Marketing). Während mehrere Einsätze in verschiedenen Teams in der Pflegekasse wurde klar - da liegen ihre Interessen. Nach der Ausbildung wechselte sie also zur Pflegekasse und arbeitet nun dort als Sachbearbeiterin und steht Pflegebedürftigen und deren Angehörigen mit Rat und Tat zur Seite.',
      projectRelationMeli: 'Erste Erfahrungen wurden bereits während der Schulzeit, in Form eines Praktikums in einer Pflegeeinrichtung, gesammelt',
      
      dateAnna0: '2017 - heute',
      dateAnna1: 'Bezug zum Projekt',
      personalTextAnna: 'Nach einer Friseurausbildung und einem anschließenden Studium in den Feldern Computergrafiker, UI-Designer, Projekt- und Eventmanager, arbeitet Anna nun Vollzeit im Tagungs- und Veranstaltungszentrum Gut Haferkorn in Dobernitz, zusätzlich in den Bereichen Nachhaltigkeit, Social Media und Design-Angelegenheiten.',
      projectRelationAnna: 'Erste Erfahrungen in Sachen Pflege entstanden dabei in der Friseurbranche. Die Idee fand sich später im Service durch Kollegen, die ehemals in der Pflege tätig waren. Der Wunsch nach einer sozialen Tätigkeit besteht allerdings schon seit der Schulzeit 2015',
    
    },
    projectWork: {
      whatNowTitle: 'UND WAS NUN?',
      whatNowSubtitle: 'Da wir noch in den Startlöchern stehen, gibt es noch einiges zu tun, bevor es richtig losgeht. Was es alles noch braucht und was wir für Vorstellungen haben, erfahrt ihr hier.',
      card0: {
        title: 'Money money money',
        subtitle: 'Ist nicht alles, aber wichtig. Neben unserem eigenem Erspartem sammeln wir weiterhin für einen reibungslosen Start.',
      },
      card1: {
        title: 'Gründung',
        subtitle: 'Die Liontaria GbR gibt es nun offiziell!',
      },
      card2: {
        title: 'Bekanntheit steigern',
        subtitle: 'Wir haben schon einige Kontakte geknüpft und uns vernetzt. Möchtest auch du Teil des Netzwerkes sein? ',
      },
      card3: {
        title: 'Immobilie schnappen',
        subtitle: 'Unser Ziel ist es, alte Gebäude zu restaurieren und altes Gemäuer wieder nutzbar zu machen. ',
      },
      card4: {
        title: 'Partner finden',
        subtitle: 'Wir finden, in der Menschlichkeit gibt es keine Konkurrenz. Lasst uns eine Gemeinschaft sein mit dem Ziel, unsere Welt ein wenig schöner zu gestalten.',
      },
      card5: {
        title: 'Einzug',
        subtitle: 'Und so beginnt unser Projekt.',
      },
      ourGoals: {
        title: 'UNSERE ZIELE:',
        paragraph0: 'Nach dem Abitur oder in der Lehre ist es fast unmöglich, große Summen anzusparen. Man steht auf eigenen Füßen und muss sich in der Welt nun alleine zurecht finden. Dennoch wollen wir jetzt etwas bewirken und nicht erst, wenn wir älter sind und andere Sorgen haben. ',
        paragraph1: 'In diesem Moment ist unser Tatendrang am größten und unser Projekt gefragt. Wir möchten auch unseren Großeltern ermöglichen, in Frieden und unter fairen Umständen ihre Rente zu genießen.',
        paragraph2: 'Und mit der Unterstützung von vielen weiteren Seelengenossen können wir dieses Ziel auch wirklich in die Tat umsetzen.',
        argument0: 'Bezahlbare Plätze',
        argument1: 'Eine faire Bezahlung unseres Teams',
        argument2: 'Eine schönere Verbindung zum Altern schaffen',
        argument3: 'Einen würdevollen Umgang mit unserer älteren Generation',
        argument4: 'Gemeinsamkeit statt Einsamkeit',
      },
      pieChart: {
        title: 'Sieh dir hier den Fortschritt unserer Spendenkampagne an',
      },
      supportUs: {
        title: 'Und hier kannst auch du uns unterstützen',
      },
    
    },
    ourProjects: {
      neighborHelp: {
        title: 'Nachbarschaftshilfe',
        subtitle: 'Folgende Dienstleistungen bieten wir momentan im Bezirk Leisnig und Umgebung an:',

        option0: {
          title: 'Haushaltshilfe',
          subtitle: 'Sei es der Frühjahrsputz, die Instandhaltung des Hauses oder der Wohnung und alles was dazu gehört - wir sind dabei. ',
        },
        option1: {
          title: 'Haustier-Betreuung',
          subtitle: 'Falls ein Tier über einen längeren Zeitraum alleine bleiben muss oder die Kraft im Alltag fehlt, übernehmen wir gerne diese Aufgabe(n).',
        },
        option2: {
          title: 'Arztbegleitung und Behördengänge',
          subtitle: 'Wir wissen, wie schnell man den Überblick über die ganzen Dokumente, Termine und Anträge vergessen kann und möchten da gerne aushelfen.',
        },
        option3: {
          title: 'Einkaufshilfe',
          subtitle: 'Wasserschleppen und stundenlange Einkäufe müssen nicht mehr alleine bewerkstelligt werden. ',
        },
        option4: {
          title: 'Fahrdienste',
          subtitle: 'Kein Auto oder kein Führerschein? Kein Problem. Wir haben beides.',
        },
        option5: {
          title: 'Friseur zu Hause',
          subtitle: 'Ob einfach zum wieder Wohlfühlen oder für bevorstehende Termine kommt ein Geselle direkt nach Hause.',
        },
      },
      ourValues: {
        title: 'UNSERE GRUNDWERTE',
        subtitle: 'Folgende Dienstleistungen bieten wir momentan im Bezirk Leisnig und Umgebung an:',

        option0: {
          title: 'Menschlichkeit',
          subtitle: 'In einem sozialen Beruf dürfen Menschlichkeit, Respekt und Verständnis auf keinen Fall zu kurz kommen',
        },
        option1: {
          title: 'Dankbarkeit',
          subtitle: 'Falls ein Tier über einen längeren Zeitraum alleine bleiben muss oder die Kraft im Alltag fehlt, übernehmen wir gerne diese Aufgabe(nZufriedenheit entsteht durch Dankbarkeit, auch für die kleinen Dinge. Wir sind alle reicher, als wir auf Anhieb glauben',
        },
        option2: {
          title: 'Vernetzung/Verbindung',
          subtitle: '"Schneidest du mir die Haare? Dafür repariere ich deine Nachttischlampe. Jeder profitiert von jedem',
        },
        option3: {
          title: 'Stetige Weiterbildung',
          subtitle: 'Wir glauben, das Leben ist zum Lernen da. Und man lernt bekanntlich nie aus. Es gibt immer Möglichkeiten, seinen Horizont zu erweitern',
        },
        option4: {
          title: 'Berufung leben',
          subtitle: 'Wer seinen Traumjob voll und unter besten Bedingungen ausleben kann, wird nie mehr arbeiten müssen',
        },
      },
    },
      
  },
  'en': {
    welcomeMessage: 'this is a test',
    testMessage: 'Hi there this is a test',
  },
};


// export function loadLocaleMessages() {
//     const locales = require.context(
//       "@/locales",
//       true,
//       /[A-Za-z0-9-_,\s]+\.json$/i
//     );
//     const messages = {};
//     locales.keys().forEach(key => {
//       const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//       if (matched && matched.length > 1) {
//         const locale = matched[1];
//         messages[locale] = locales(key);
//       }
//     });
//     return messages;
//   }
  

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: messages,
});
