<template>
    <div>
        <!-- replace with margin/padding -->
        <v-container class="full-screen-banner">
            <vs-row>
            </vs-row>
        </v-container>
      <v-img
          :src="require('../assets/old_people_playing_chess.jpg')"
          :height="fit-content"
          aspect-ratio="16/9"
          >
          <div>
            <v-row>
            <v-responsive v-if="isMobile()" width="100%"></v-responsive>
                <v-col v-if="isMobile()" class="column">
                  <ThreeFold class="text" text1="Eine Basis Viele Möglichkeiten"
                        text2="Das Konzept einer Senioren-WG ist bereits verbreitet und bringt einige Vorteile mit sich"
                        text3="Hier findest du einige davon. Natürlich liegt es bei jedem selbst, ob er sich mit dieser Form der Gemeinschaft identifizieren kann. "
                        >
                </ThreeFold>
                </v-col>
                <v-responsive v-if="isMobile()" width="100%"></v-responsive>
                <v-col class="column">
                    <!-- <PhotoWithText msg= 'partner_melli_cropped.jpg'
                    :subtitle0="$t('aboutUsText.personalTextMeli')"
                    :date0="$t('aboutUsText.dateMeli0')"
                    :subtitle1="$t('aboutUsText.projectRelationMeli')"
                    :date1="$t('aboutUsText.dateMeli1')"/> -->
                </v-col>

                <v-responsive  v-if="isMobile()" width="100%"></v-responsive>

                <v-col v-if="!isMobile()" class="bg">
                  <ThreeFold class="text" text1="Eine Basis Viele Möglichkeiten"
                        text2="Das Konzept einer Senioren-WG ist bereits verbreitet und bringt einige Vorteile mit sich"
                        text3="Hier findest du einige davon. Natürlich liegt es bei jedem selbst, ob er sich mit dieser Form der Gemeinschaft identifizieren kann. "
                        >
                </ThreeFold>

                </v-col>
                <v-responsive  v-if="isMobile()" width="100%"></v-responsive>
                <v-col class="column">
                    <!-- <PhotoWithText msg= 'partner_anna_cropped.jpg'
                    :subtitle0="$t('aboutUsText.personalTextAnna')"
                    :date0="$t('aboutUsText.dateAnna0')"
                    :subtitle1="$t('aboutUsText.projectRelationAnna')"
                    :date1="$t('aboutUsText.dateAnna1')"/> -->
                </v-col>
            </v-row>
          </div>
        </v-img>
        
      <GridComponent></GridComponent>
        

        <FooterComponent></FooterComponent>


    </div>
</template>
<script>

// import PhotoWithText from './PhotoWithText.vue';
// import JustText from './Text.vue';
import ThreeFold from './ThreeFold.vue';
import FooterComponent from './Footer.vue';
// import FullScreenCenterBanner from './FullScreenCenterBanner.vue';
// import SmallPhotoWithText from './SmallPhotoWithText.vue';
import GridComponent from './GridComponent.vue';
export default {
  name: 'OurProjects',
  

  components: {
    ThreeFold,
    FooterComponent,
    // FullScreenCenterBanner,
    // SmallPhotoWithText,
    GridComponent, 
  },
  data: () => ({
   
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
};
</script>


<style-scoped>

.column{
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 0%;
    padding-right: 0%;

}
.green{
  background-color: green;
}

.our-goals-container{
  height:300px;
  background-color: #cbd3bb !important;
  color:black;

}

.xx-spacer{
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: larger;
}
.xxx-spacer{
  padding-top: 100px;
  padding-bottom: 50px;
  font-size: larger;
}
.center{
  margin-right: auto;
  margin-left: auto;

}


</style>


