<template>
    <div class="full-screen-banner">
        <v-img 
        :src="img"
        :height="300"
        position="center"
        >
        <div v-if="side == 'left'" class="left-text">
            <div class="box">
              <div class="subtitle">{{title}}</div>
              <p>{{subtitle}}</p>
            </div>
        </div>
        <div v-else-if="side == 'center'" class="center-text">
            <div class="subtitle">{{title}}</div>
            <p>{{subtitle}}</p>
        </div>
        <div v-else-if="side == 'right'" class="right-text">
          <div class="box">
            <div class="subtitle">{{title}}</div>
            <p>{{subtitle}}</p>
          </div>
           
        </div>
                </v-img>
            </div>
</template>
  
<script>
export default {
  name: 'FullScreenBanner',
  props: {
    title: String,
    subtitle: String,
    msg: String,
    side: String,
  },
  data: () => ({
  }),
  computed: {
    img() {
      return require('@/assets/' + this.msg + '');
    },
    smallImage() {
      return require('@/assets/' + this.logo + '');
    },
    
  },
};

</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.full-screen-banner {
    margin-top: 40px;

}

/* Place text in the middle of the image*/

.center-text{
  background-color: white;
    opacity: 70%;
    text-align: center;
    padding-top: 90px;
    padding-left: 70px;
    padding-right: 70px;
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    /* transform: translate(-50%, -50%); */
    color: black;

}

.right-text {
    background-color: white;
    opacity: 50%;
  
    text-align: center;
    /* padding-top: 70px; */
    padding-left: 20px;
    padding-right: 70px;
    position: absolute;
    top: 50%;
    left: 80%;
    height: 100%;
    width: 50%;
    transform: translate(-60%, -50%);
    color: black;


}

.left-text {
    background-color: white;
    opacity: 50%;
    text-align: center;
    /* padding-top: 70px;
    padding-right: 20px;
    padding-left: 40px; */
    position: absolute;
    top: 50%;
    left: 20%;
    height: 100%;
    width: 50%;
    transform: translate(-40%, -50%);
    color: black;

}
.subtitle{
  font-size: x-large;
  font-weight: bold;
  padding-bottom:10px;
}

.box{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  max-width: 150px;
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
