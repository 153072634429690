<template>
    <div class="full-height-banner">
          <p class="title">{{title}}</p>
          <p class="subtitle">{{subtitle0}}</p>
          <p class="subtitle">{{subtitle1}}</p>
          <p class="subtitle">{{subtitle2}}</p>
          <v-divider></v-divider>
    </div>
</template>
  
<script>
export default {
  name: 'FullScreenCenterBanner',
  props: {
    title: {
      type: String,
    },
    subtitle0: {
      type: String,
    },
    subtitle1: {
      type: String,
    },
    subtitle2: {
      type: String,
    },
  },
  data: () => ({
  }),
  computed: {
  },
};

</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.full-height-banner {
  height: fit-content;
  padding-top: 50px;

  padding-right:30%;
  padding-left: 30%;

 
}

.center-text{
  text-align: center;
  position: absolute;
  width: 100%;

    
}

.title{
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 20px;

  
}
.subtitle{
  font-size: large;
  line-height: 35px;
  padding-bottom: 20px;

}

.center{
  margin-left: auto;
  margin-right: auto;
}
</style>
