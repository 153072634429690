<template>
   <div>
    <FullScreenCenterBanner
        class="our-goals-container"
        :title="$t('ourProjects.neighborHelp.title')"
        :subtitle0="$t('ourProjects.neighborHelp.subtitle')"
        />
        <div class="our-goals-container ">
        <v-container class="center ">
          <v-row class="divider">   
            <v-spacer v-if="!isMobile()"></v-spacer>
            <v-col cols="12" lg="2" xs="12" class="center">
              <SmallPhotoWithText msg='cleaning.jpg'
                :subtitle0="$t('ourProjects.neighborHelp.option0.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option0.title')"
              />
            </v-col>
            <v-col cols="12" lg="2" xs="12" class="center">
              <SmallPhotoWithText msg= 'heart.png'
                :subtitle0="$t('ourProjects.neighborHelp.option1.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option1.title')"
              />
              
            </v-col>
            
            <v-col cols="12" lg="2" xs="3" class="center">
              
              <SmallPhotoWithText msg= 'doctor.jpg'
              :subtitle0="$t('ourProjects.neighborHelp.option2.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option2.title')"
              />
            </v-col>
            <v-col cols="12" lg="2" xs="3" class="center">
              <SmallPhotoWithText msg= 'grocery.jpg'
              :subtitle0="$t('ourProjects.neighborHelp.option3.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option3.title')"
              />
            </v-col>
         
            <v-col cols="12" lg="2" xs="3" class="center">
              
              <SmallPhotoWithText msg= 'car.jpg'
              :subtitle0="$t('ourProjects.neighborHelp.option4.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option4.title')"
              />
            </v-col>
          
            <v-col cols="12" lg="2" xs="3" class="center">
              <SmallPhotoWithText msg= 'hair.jpg'
              :subtitle0="$t('ourProjects.neighborHelp.option5.subtitle')"
                :date0="$t('ourProjects.neighborHelp.option5.title')"
              />
            </v-col>
            <v-spacer v-if="!isMobile()"></v-spacer>
          </v-row>
       
        </v-container>
        </div>
        
    <FullScreenCenterBanner
        class="our-goals-container2"
        :title="$t('ourProjects.ourValues.title')"
        :subtitle0="$t('ourProjects.ourValues.subtitle')"
        />
        <div class="our-goals-container2">
        <v-container class="center">
          <v-row class="divider">   
            <v-spacer v-if="!isMobile()"></v-spacer>
            <v-col cols="12" lg="2" xs="12" class="center">
              <SmallPhotoWithText msg= 'humanity.png'
                :subtitle0="$t('ourProjects.ourValues.option0.subtitle')"
                :date0="$t('ourProjects.ourValues.option0.title')"
              />
            </v-col>
            <v-col cols="12" lg="2" xs="12" class="center">
              <SmallPhotoWithText msg= 'give.png'
                :subtitle0="$t('ourProjects.ourValues.option1.subtitle')"
                :date0="$t('ourProjects.ourValues.option1.title')"
              />
              
            </v-col>
            
            <v-col cols="12" lg="2" xs="3" class="center">
              
              <SmallPhotoWithText msg= 'networking.png'
              :subtitle0="$t('ourProjects.ourValues.option2.subtitle')"
                :date0="$t('ourProjects.ourValues.option2.title')"
              />
            </v-col>
            <v-col cols="12" lg="2" xs="3" class="center">
              <SmallPhotoWithText msg= 'online-learning.png'
              :subtitle0="$t('ourProjects.ourValues.option3.subtitle')"
                :date0="$t('ourProjects.ourValues.option3.title')"
              />
            </v-col>
         
            <v-col cols="12" lg="2" xs="3" class="center">
              
              <SmallPhotoWithText msg= 'sun.png'
              :subtitle0="$t('ourProjects.ourValues.option4.subtitle')"
                :date0="$t('ourProjects.ourValues.option4.title')"
              />
            </v-col>
          
            <v-spacer v-if="!isMobile()"></v-spacer>
          </v-row>
          
        </v-container>
        </div>
    
      <!-- <div class="relevant-links">
        
        <v-container class="center ">
          <div class="spacer"></div>
          <v-row>   
            <v-spacer v-if="!isMobile()"></v-spacer>
            <v-col cols="7" lg="3" xs="3" class="center">
              <p>{{ $t('projectWork.pieChart.title') }}</p>
              <v-img
                :width="400"
                :height="200"
                aspect-ratio="16/9"
                cover
                src="..//assets/Omi.png"
              ></v-img>
            </v-col>
            <div v-if="!isMobile()" class="horizontal-divider"></div>
            <v-col cols="7" lg="3" xs="3" class="center shake">
              <p>{{ $t('projectWork.supportUs.title') }}</p>
              <a href="https://www.betterplace.me/wg-mal-anders">
                <v-img
                  :width="400"
                  :height="200"
                  aspect-ratio="16/9"
                  cover
                  src="..//assets/beitragen.png"
                ></v-img>
              </a>
                
              
            </v-col>
            <v-spacer v-if="!isMobile()"></v-spacer>
          </v-row>
   
        </v-container>
      </div> -->
  
  </div>
</template>

<script>
import FullScreenCenterBanner from './FullScreenCenterBanner.vue';
import SmallPhotoWithText from './SmallPhotoWithText.vue';

export default {
  name: 'GridComponent',


  data() {
    return {
      disabled: false,
    };
  },

  methods: {
    warnDisabled() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 1500);
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.order = 1;
        return true;
      } else {
        this.order = 3;
        return false;
      }
    },
  },
  components: {
    FullScreenCenterBanner,
    SmallPhotoWithText,

  },
};

</script>

<style scoped>

.image{
  /* background-image: require('@/assets/' + blumen.jpg + ''); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("..//assets/macbook_pen.jpg");
  background-size: auto;
  background-size: 100% 100%;
  /* margin-right: auto;
  margin-left: auto; */
}


.custom-title {
  font-size: 18px;
  color: rgb(84, 35, 35); /* Adjust the font size as needed */
  /* Add any other title styles you need */
}

.custom-text {
  font-size: 8px; /* Adjust the font size as needed */
  /* Add any other text styles you need */
}

.custom-column{
  padding-left: 20px;
}
.custom-row{
margin: 1px;
}

.center{
  margin-right:auto;
  margin-left: auto;

}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.spacer{
  margin-bottom: 15px;
}
.x-spacer {
  padding-top: 50px;
}
.divider{
  margin-bottom: 10%;
}
.what-now-container{
  background-color: #eaeddf;
  color:black;
}
.our-goals-container{
  background-color: #eaeddf;
  color:black;


}
.our-goals-container2{
  background-color: #cdd4bc;
  color:black;

}
.relevant-links{
  background-color:  #eaeddf;
  color:black;
}
.xx-spacer{
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: larger;
}
.xxx-spacer{
  padding-top: 100px;
  padding-bottom: 50px;
  font-size: larger;
}
.horizontal-divider{
  margin-right: 50px;
}

</style>
